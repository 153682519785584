import React, { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

function Contact() {
  useEffect(() => {
    let map;

    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: markerIcon2x,
      iconUrl: markerIcon,
      shadowUrl: markerShadow,
    });

    if (!map) {
      const centerCoordinates = [52.33916, 1.13307];

      map = L.map('map', {
        center: centerCoordinates,
        zoom: 16,
        maxBounds: [
          [52.29916, 1.09307],
          [52.37916, 1.17307],
        ],
        maxBoundsViscosity: 1.0,
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      L.marker(centerCoordinates)
        .addTo(map)
        .bindPopup(
          `
          <div style="text-align: center;">
            <strong>Horizon Seeds</strong><br />
            Unit 3 Airfield Ind Park<br />
            Langton Green Eye<br />
            Suffolk<br />
            IP23 7HN
          </div>
          `,
          {
            permanent: true,
            closeButton: false,
          }
        )
        .openPopup();
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, []);

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <div className="contact-info">
        <p><strong>Telephone:</strong> 01379 873 377</p>
        <p><strong>Email:</strong> <a href="mailto:info@horizonseeds.com">info@horizonseeds.com</a></p>
      </div>

      <div id="map" style={{ height: '50vh', width: '100%' }}></div>
    </div>
  );
}

export default Contact;
