import React, { useState, useEffect } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const videoPosts = [
  {
    title: 'Neil chased up the clamp',
    date: '2024-12-09',
    excerpt: 'Neil Groom demonstrates his nifty footwork.',
    videoUrl: 'https://player.vimeo.com/video/1037432965',
    thumbnailUrl: '',
  },
];

const sanitizeInput = (value) => {
  return value.replace(/[^\w\s.,@()-]/gi, '');
};

const Videos = () => {
  const [videos, setVideos] = useState(videoPosts);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  // Fetch thumbnails on mount
  useEffect(() => {
    const fetchThumbnails = async () => {
      const updatedVideos = await Promise.all(
        videos.map(async (video) => {
          try {
            const videoId = video.videoUrl.split('/').pop();
            const response = await fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`);
            const data = await response.json();
            return { ...video, thumbnailUrl: data.thumbnail_url };
          } catch (error) {
            console.error('Error fetching thumbnail:', error);
            return video; // Fallback to the original video object
          }
        })
      );
      setVideos(updatedVideos);
    };

    fetchThumbnails();
  }, []);

  const toggleModal = (video) => {
    setSelectedVideo(video);
    setModalOpen(!isModalOpen);
  };

  const filteredVideos = videos
    .filter(
      (video) =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        video.excerpt.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((video) => (filterDate ? video.date === filterDate : true))
    .sort((a, b) =>
      sortOrder === 'asc'
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date)
    );

    const handleSearchChange = (e) => {
      setSearchTerm(sanitizeInput(e.target.value));
    };

  return (
    <div className="videopost-container">
      <h1>Horizon Seeds Videos</h1>
      <div className="videopost-filters">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="videopost-search"
        />
        <input
          type="date"
          value={filterDate}
          onChange={(e) => setFilterDate(e.target.value)}
          className="videopost-date-filter"
        />
        <select
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
          className="videopost-sort"
        >
          <option value="asc">Sort by Date (Ascending)</option>
          <option value="desc">Sort by Date (Descending)</option>
        </select>
      </div>
      <div className="videopost-list">
        {filteredVideos.map((video, index) => (
          <div key={index} className="videopost-card">
            <h2 className="videopost-title">{video.title}</h2>
            <p className="videopost-date">{new Date(video.date).toLocaleDateString()}</p>
            {video.thumbnailUrl && (
              <img src={video.thumbnailUrl} alt={video.title} className="videopost-thumbnail" />
            )}
            <p className="videopost-excerpt">{video.excerpt}</p>
            <button
              className="videopost-watch-button"
              onClick={() => toggleModal(video)}
            >
              Watch Video
            </button>
          </div>
        ))}
      </div>

      {isModalOpen && selectedVideo && (
        <div className="videopost-modal-overlay">
          <div className="videopost-modal-content">
            <button className="videopost-close-modal" onClick={() => toggleModal(null)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="videopost-modal-header">
              <h2 className="videopost-modal-title">{selectedVideo.title}</h2>
              <p className="videopost-modal-date">
                {new Date(selectedVideo.date).toLocaleDateString()}
              </p>
            </div>
            <div className="videopost-modal-body">
              <iframe
                src={selectedVideo.videoUrl}
                width="85%"
                height="85%"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title={selectedVideo.title}
                className="videopost-embed"
              ></iframe>
            </div>
            <p className="videopost-modal-excerpt">{selectedVideo.excerpt}</p>
            <button
              className="videopost-go-back-button"
              onClick={() => toggleModal(null)}
            >
              Go Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Videos;
