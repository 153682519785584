import React from 'react';
import slaveryStatement from './assets/pdfs/GRAINSEED-Slavery-and-Human-Trafficking-Statement.pdf';
import termsConditions from './assets/pdfs/Horizon-Seeds-Terms-and-Conditions-of-Sale-July-2023.pdf';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 Horizon Seeds. All rights reserved.</p>
        <div className="footer-links">
          <a href="/contact">Contact Us</a>
          <a href={termsConditions} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
          <a href={slaveryStatement} target="_blank" rel="noopener noreferrer">Slavery & Human Traffic Statement</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
