import React, { useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import dec24Image from './assets/images/dec24.jpg';

const blogPosts = [
  {
    title: 'AHDB List published, with Pinnacle as top conventional rape',
    date: '2024-12-04',
    excerpt: 'Pi Pinnacle remains the best conventional variety on the 2025 Recommended list for winter oilseed rape, published this week...',
    content: `
    Neil Groom, general manager for Grainseeds, is glad to see that their variety Pi Pinnacle remains the best conventional variety on the 2025 Recommended list for winter oilseed rape, published this week. Pi Pinnacle was the highest yielding conventional Oilseed rape variety at 102% and has been selected as a control variety for these 2025 Recommended List trials.

    Neil points out that Pinnacle now has intermediate resistance to Verticillium wilt and gave good performance compared to the control varieties in the new untreated trials that have been started in the trials matrix. “There is still no chemical answer to Verticillium wilt so farmers must rely on using variety resistance,” Neil says.

    Pinnacle was bred by Mike Pickford in the Cotswolds so is ideally suited to UK conditions. Mike selects for varieties that yield well or have a High Harvest Index. This means varieties with a lot of big pods with many large seeds. Pinnacle has seed with a thousand grain weight between 6.5 to 7gms compared to Campus at 5 to 6 gms. “I also aim to have yield components on a plant canopy structure that has thick green healthy stems to support the seed yield with excellent photosynthesis during the grain filling process; with a stiff stem and good disease resistance.”

    “The 2024 performance of Pinnacle in official and private trials and commercially on farm has shown to be consistent, with high yielding potential. In 2024 Pi Pinnacle has been the most consistent low-cost variety that can outperform many popular high-price hybrids,” says Mike.

    “Similarly, commercially on farm its performance has been consistently high with commercial yields over 4tha on many good arable soils. In 2024 we have seen good commercial sales of this new variety,” says Neil Groom.

    For further information on the oilseed rape varieties from Grainseed, please contact Neil Groom, General Manager of Grainseed on 01379 871073 or 07774 720240.
  `,
  imageUrl: dec24Image,
}
];

const sanitizeInput = (value) => {
  return value.replace(/[^\w\s.,@()-]/gi, '');
};

const Blogs = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const toggleModal = (post) => {
    setSelectedPost(post);
    setModalOpen(!isModalOpen);
  };

  const filteredPosts = blogPosts
    .filter(
      (post) =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.excerpt.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((post) => (filterDate ? post.date === filterDate : true))
    .sort((a, b) =>
      sortOrder === 'asc'
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date)
    );

    const handleSearchChange = (e) => {
      setSearchTerm(sanitizeInput(e.target.value));
    };

  return (
    <div className="blogpost-container">
      <h1>Horizon Seeds Blog</h1>
      <div className="blogpost-filters">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="blogpost-search"
        />
        <input
          type="date"
          value={filterDate}
          onChange={(e) => setFilterDate(e.target.value)}
          className="blogpost-date-filter"
        />
        <select
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
          className="blogpost-sort"
        >
          <option value="asc">Sort by Date (Ascending)</option>
          <option value="desc">Sort by Date (Descending)</option>
        </select>
      </div>
      <div className="blogpost-list">
        {filteredPosts.map((post, index) => (
          <div key={index} className="blogpost-card">
            <img src={post.imageUrl} alt={post.title} className="blogpost-image" />
            <h2 className="blogpost-title">{post.title}</h2>
            <p className="blogpost-date">{new Date(post.date).toLocaleDateString()}</p>
            <p className="blogpost-excerpt">{post.excerpt}</p>
            <button
              className="blogpost-read-more-button"
              onClick={() => toggleModal(post)}
            >
              Read More
            </button>
          </div>
        ))}
      </div>

      {isModalOpen && selectedPost && (
        <div className="blogpost-modal-overlay">
          <div className="blogpost-modal-content">
            <button className="blogpost-close-modal" onClick={() => toggleModal(null)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="blogpost-modal-header">
              <img
                src={selectedPost.imageUrl}
                alt={selectedPost.title}
                className="blogpost-modal-image"
              />
            </div>
            <div className="blogpost-modal-text">
              <h2 className="blogpost-modal-title">{selectedPost.title}</h2>
              <p className="blogpost-modal-date">
                {new Date(selectedPost.date).toLocaleDateString()}
              </p>
              {selectedPost.content.split('\n\n').map((paragraph, index) => (
                <p key={index} className="blogpost-modal-paragraph">
                  {paragraph}
                </p>
              ))}
              <button
                className="blogpost-go-back-button"
                onClick={() => toggleModal(null)}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blogs;
